import { MapContainer, TileLayer, Popup, useMap, Polygon, FeatureGroup, Rectangle, GeoJSON } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { View } from "../ui/View/View"
import { packRegions } from "../../Helper/Regions";
import { Text } from "../ui/Text/Text";
import regions from '../../regionsHelper/ua.json'


export const OldMap = ({data, colors = []}) => {
    const {latitude, longitude} = {latitude: 66.25, longitude: 94.15}

    const MapView = () => {
        let map = useMap();
        map.setView([latitude, longitude], map.getZoom());
        map.attributionControl.setPrefix('')
        
        return null;
    }
    // console.log("regions", regions?.features[0])

    return (
        <View style={{width: '100%', height: '100%'}}>
            <View style={{backgroundColor: 'rgba(217, 214, 214, 0.698)',borderRadius: 35,padding: 16,flexDirection: 'column',position: 'absolute',zIndex: 999999,left: 16,bottom: 16}}>
                {
                    colors && colors.map((el, key) => {
                        return (
                            <View style={{display: 'flex', alignItems: 'center', marginBottom: 8}}>
                                <View style={{backgroundColor: el?.color, width: 38, height: 38, borderRadius: 100}}></View>
                                <View style={{backgroundColor: '#000', width: 5, height: 5, borderRadius: 100, margin: "0 8px"}}></View>
                                <View>
                                    <Text style={{color: "#000", fontWeight: '400', fontSize: 12}}>{el?.text}</Text>
                                </View>
                            </View>
                        )
                    })
                }
            </View>
            <MapContainer
                classsName="map"
                center={[latitude, longitude]}
                zoom={3}
                scrollWheelZoom={true}
                bounds={[[43.22443049072825, 36.53048595858945], [47.003021442733484, 41.74397969255338]]}
            >
                <TileLayer
                    attribution='&copy; Neoside'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {
                    data?.map((el, key) => {
                        if (!packRegions?.[el?.region?.name]) {
                            return <View key={`key-map-polygon-index${key}`} />;
                        }

                        if (packRegions?.[el.region.name]?.[0]?.type === "Polygon" || packRegions?.[el.region.name]?.[0]?.type === "FeatureCollection" || packRegions?.[el.region.name]?.[0]?.type === "MultiPolygon") {
                            return (
                                <GeoJSON
                                    key={`key-map-polygon-index${key}`}
                                    data={packRegions?.[el?.region?.name]?.[0]||[]}
                                    pathOptions={{color: el?.color, fillColor: el?.color, stroke: true, fillOpacity: 0.4}}
                                >
                                    <Popup>
                                        <View style={{marginBottom: 6}}>
                                            <Text style={{textDecoration: "underline", fontWeight: '700'}}>
                                                {el?.region?.name||"null"}
                                            </Text>
                                        </View>
                                        <View>
                                            <Text style={{display: 'block'}}>{el?.value||"0"}</Text>
                                        </View>
                                    </Popup>
                                </GeoJSON>
                            )
                        }

                        return (
                            <Polygon
                                key={`key-map-polygon-index${key}`}
                                pathOptions={{color: el?.color, fillColor: el?.color, stroke: true, fillOpacity: 0.4}}
                                positions={packRegions?.[el?.region?.name]?.[0]||[]}
                            >
                                <Popup>
                                    <View style={{marginBottom: 6}}>
                                        <Text style={{textDecoration: "underline", fontWeight: '700'}}>
                                            {el?.region?.name||"null"}
                                        </Text>
                                    </View>
                                    <View>
                                        <Text style={{display: 'block'}}>{el?.value||"0"}</Text>
                                    </View>
                                </Popup>
                            </Polygon>
                        )
                    })
                }
                <MapView />
            </MapContainer>
        </View>
    )
}