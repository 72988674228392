import DatePicker, { registerLocale } from "react-datepicker"
import ru from "date-fns/locale/ru";
import { ConfigProject } from "../../../Helper/ConfigProject"
import { View } from "../View/View"
registerLocale("ru", ru);

export const DatePeriodUI = ({
    periodStart = 0,
    periodEnd = 0,
    handleDatePicker = null
}) => {

    return (
        <>
        <View style={{marginRight: 8}}>
            <DatePicker
                showIcon
                selected={periodStart}
                dateFormat="dd/MM/yyyy"
                onChange={(date) => handleDatePicker && handleDatePicker(date, 'start')}
                style={{marginRight: 8}}
                locale="ru"
            />
        </View>
        <DatePicker
            showIcon
            disabled
            selected={periodEnd}
            dateFormat="dd/MM/yyyy"
            // onChange={(date) => handleDatePicker && handleDatePicker(date, 'end')}
            locale="ru"
        />
        </>
    )
}

const RootStyle = {
    btn: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        color: ConfigProject.colorDefault,
        fontWeight: '400',
        lineHeight: "24px",
        textAlign: "center",

        minHeight: "40px",
        borderWidth: 1,
        borderColor: ConfigProject.colorDefault,
        borderStyle: 'solid',
        cursor: "pointer",
    },
    containerRow: {
        marginBottom: 15,
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
    },
    containerRowColumn: {
        flex: 1,
        // margin: "0 15px"
    },
    startBorder: {},
    endBorder: {}
}