import React, { useState } from "react"
import { View } from "../Components/ui/View/View"
import DatePicker, { registerLocale } from "react-datepicker"
import { Text } from "../Components/ui/Text/Text"
import { ButtonUI } from "../Components/ui/Buttons/ButtonUI"
import { useRootStore } from "../core/hooks/useRootStore"
import { observer } from "mobx-react-lite"
import ru from "date-fns/locale/ru";
import DateHelper from "../core/helpers/DateHelper"
import { DatePeriodUI } from "../Components/ui/Input/DatePeriodUI"
registerLocale("ru", ru);

export const ProfileRegionScreen = observer(() => {
    const {
        statisticStore
    } = useRootStore();

    const [datePeriod, setDatePeriod] = useState({
        start: DateHelper.getDatePickerDefault().monday,
        end: DateHelper.getDatePickerDefault().sunday,
    })

    const handleSave = async () => {
        try {
            const res = await statisticStore.fileExport({
                period_start: datePeriod.start.getTime(),
                period_end: datePeriod.end.getTime(),
            })
            
            if (res?.file) {
                window.open(res?.file);
            }   
        } catch (error) {
            
        }
    }

    const handleDatePicker = (date, type) => {
        const getFormatDate = DateHelper.getDatePickerFromDate(date)
        setDatePeriod({
            start: getFormatDate.monday.getTime(),
            end: getFormatDate.sunday.getTime()
        })
    }

    console.log("datePeriod", datePeriod)

    return (
        <View className="flex" style={{flexDirection: 'column', alignItems: 'center'}}>
            <View className="flex-1" style={{marginBottom: 28}}>
                <View style={{display: 'block', marginBottom: 6, textAlign: 'center'}}>
                    <Text>Скачать файл за Период</Text>
                </View>
                
                <View style={{display: 'flex'}}>
                    <DatePeriodUI
                        periodStart={datePeriod.start}
                        periodEnd={datePeriod.end}
                        handleDatePicker={handleDatePicker}
                    />
                </View>
            </View>
            <View className="flex-1" style={{margin: '0 16px'}}>
                <View>
                    <ButtonUI
                        text={"Скачать отчёт"}
                        onHandle={handleSave}
                        btn={statisticStore.fileExportLoader ? false : true}
                        btnLoad={statisticStore.fileExportLoader}
                    />
                </View>
            </View>
        </View>
    )
})