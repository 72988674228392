import React, { useRef, useState } from "react"
import { View } from "../Components/ui/View/View"
import DatePicker, { registerLocale } from "react-datepicker"
import { Text } from "../Components/ui/Text/Text"
import { ButtonUI } from "../Components/ui/Buttons/ButtonUI"
import { ImageUI } from "../Components/ui/Image/ImageUI"
import { useRootStore } from "../core/hooks/useRootStore"
import ru from "date-fns/locale/ru";
import { observer } from "mobx-react-lite"
import DateHelper from "../core/helpers/DateHelper"
import { DatePeriodUI } from "../Components/ui/Input/DatePeriodUI"
registerLocale("ru", ru);

export const ProfileAdminScreen = observer(() => {
    const {
        statisticStore
    } = useRootStore();

    const fileInputRef = useRef();
    const [datePeriod, setDatePeriod] = useState({
        start: DateHelper.getDatePickerDefault().monday.getTime(),
        end: DateHelper.getDatePickerDefault().sunday.getTime(),
    })

    const handleFileChange = async (event) => {
        try {
            const res = await statisticStore.fileImport({
                period_start: datePeriod.start,
                period_end: datePeriod.end,
                file: event.target.files[0]
            })

            if (res) {
                alert('Файл успешно загружен!')
            }
        } catch (error) {
            
        }
    }

    const handleDatePicker = (date, type) => {
        const getFormatDate = DateHelper.getDatePickerFromDate(date)
        setDatePeriod({
            start: getFormatDate.monday.getTime(),
            end: getFormatDate.sunday.getTime()
        })
    }

    return (
        <View className="flex">
            <View className="flex-1"></View>
            <View className="flex-1">
                <View style={{display: 'block', marginBottom: 6}}>
                    <Text style={{marginRight: 8}}>Период:</Text>
                </View>
                <View style={{display: 'flex'}}>
                    <DatePeriodUI
                        periodStart={datePeriod.start}
                        periodEnd={datePeriod.end}
                        handleDatePicker={handleDatePicker}
                    />
                </View>
            </View>
            <View className="flex-1" style={{margin: '0 16px'}}>
                <View style={{textAlign: 'center', marginBottom: 62}}>
                    <ImageUI src={require('../assets/images/downloadfolder.png')} />
                </View>
                <View>
                    <ButtonUI
                        text={"Загрузить файл"}
                        onHandle={() => fileInputRef.current.click()}
                        btn={statisticStore.fileImportLoader ? false : true}
                        btnLoad={statisticStore.fileImportLoader}
                    />
                    <input type="file" onChange={handleFileChange} accept=".xlsx" ref={fileInputRef} hidden />
                </View>
            </View>
            <View className="flex-1"></View>
            <View className="flex-1"></View>
        </View>
    )
})